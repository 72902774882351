@import "../mixins";


.p-tabmenu.mainTabMenu {
  @include mobile {
    background-color: white;
    z-index: 100;
    border-top: 1px solid #ccc;
    width: 100%;
    height: 55px;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 11px;
    font-weight: 300 !important;
  }

  .p-tabmenu-nav {
    @include mobile {
      border: 0;
      justify-content: space-around;
    }

    .p-tabmenuitem {
      .p-menuitem {
        &-link {
          font-weight: 300;
          @include mobile {
            padding: 12px 5px 6px 5px;
            border: 0 !important;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .p-menuitem-icon {
              padding: 0 0 7px 0;
              margin: 0;
            }
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        &-text {
          @include mobile {
            font-weight: 300;
          }
        }
      }

      &.itemSep {
        flex-grow: 2;

        > a {
          display: none;
        }

        @include mobile {
          display: none;
        }
      }

      &.stickRight {
        .p-menuitem-link {
          display: inline-flex;
        }

        @include mobile {
          display: none;
        }
      }
    }
  }
}

:host ::ng-deep .docListTabs {
  //position: fixed;
  top: -50px;
  z-index: 100;
  width: calc(100% + 42px);

  a.p-menuitem-link {
    padding: 7px 1rem !important;
    font-size: 14px;
    font-weight: 300;

    span {
      font-weight: 400;
    }
  }
}
