@import 'vars';
//@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
//    // For Google Chrome
//    &::-webkit-scrollbar {
//        width: $size;
//        height: $size;
//    }
//    &::-webkit-scrollbar-thumb {
//        background: $foreground-color;
//    }
//    &::-webkit-scrollbar-track {
//        background: $background-color;
//    }
//    // For Internet Explorer
//    & {
//        scrollbar-face-color: $foreground-color;
//        scrollbar-track-color: $background-color;
//    }
//}

@mixin mobile {
    @media screen and (max-width: $mobileWidth) {
        @content;
    }
}

@mixin mobilePlus {
    @media screen and (min-width: $mobileWidth) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: $mobileWidth) {
        @content;
    }
}

@mixin smallest {
    @media (min-width: $smallest) {
        @content;
    }
}
@mixin small {
    @media (min-width: $small) {
        @content;
    }
}
@mixin medium {
    @media (min-width: $medium) {
        @content;
    }
}
@mixin large {
    @media (min-width: $large) {
        @content;
    }
}


