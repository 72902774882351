@import "../utilities/derived-variables"

$footer-background-color: $scheme-main-bis !default
$footer-color: false !default
$footer-padding: 0 !default

.footer
  background-color: $footer-background-color
  padding: $footer-padding
  @if $footer-color
    color: $footer-color
