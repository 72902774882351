$small: 768px;
$medium: 1024px;
$large: 1440px;
$mobileWidth: 768px;
$smallest: 361px;

$red: #FA4022;
$mainColor: #FF4123;
$footer: #C7C7C7;
$textCalc: #ACACAC;
$mediumGray: #C4C4C4;
$lightGray: #E6E6E6;
$background: #f8f8f9;
$colorBlue: #2196C3;
$textDark: #232323;
$textLight: #9B9B9B;
$green: #45B75C;
$gray: #6D6D6D;
