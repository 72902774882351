@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans/OpenSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans/OpenSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "ALSSiriusMed";
  src: url("/assets/fonts/ALSSiriusMed-Medium-2.otf.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Commissioner";
  src: url("../fonts/commissioner/Commissioner-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Commissioner";
  src: url("../fonts/commissioner/Commissioner-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Commissioner";
  src: url("../fonts/commissioner/Commissioner-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Commissioner";
  src: url("../fonts/commissioner/Commissioner-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}