@import "fonts";
@import "mixins";
@import "vars";

html, body {
  min-height: 100vh;
  background-color: white;
  font-family: "Commissioner", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: black;

  &.page-loaded {
    @include mobile {
      background-color: #f5f5f5;
    }
  }
}

a {
  color: $colorBlue;
}

h4 {
  margin-bottom: 24px;
}

hr {
  background: #DBDEDF;
  box-shadow: none;
  height: 1px;
  border: 0;
}

p.title {
  font-weight: 400;
  margin-bottom: 4px;
}

.inputWide {
  width: 100% !important;

  > span, input.p-inputtext, .p-component {
    width: 100% !important;
  }
}
.p-component {
  font-family: "Commissioner", serif;
}

.text-black {
  color: $textDark;
}

.text-white {
  color: white;
}

.fs-5 {
  font-size: 2rem;
}

.fs-8 {
  font-size: 1.40rem !important;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-column-gap: 1rem;
  @media (min-width: 768px) {
    grid-column-gap: 1.5rem;
  }
}

.d-grid-diagram {
  display: grid;
}

.bg-main {
  background: $mainColor;
}

.pa-mt-5 {
  margin-top: 1.5rem !important;
}

.pa-mb-4 {
  margin-bottom: 1rem !important;
}

.pa-mb-5 {
  margin-bottom: 1.5rem !important;
}

.pa-fs-5 {
  font-size: 2rem;
}

.pa-py-5 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}