::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: #E6E6E6;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(180, 0, 170, 0.2);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #E6E6E6;
}

::-webkit-scrollbar-thumb:vertical {
  background-color: #FF4123;
}

::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #d02020;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #FF4123;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: #d02020;
}