@import 'node_modules/primeflex/core/_variables';
@import 'node_modules/primeflex/core/_mixins';
@import 'node_modules/primeflex/core/_utils';
@import 'node_modules/primeflex/core/_flexbox';
@import 'node_modules/primeflex/core/_grid';
@import 'node_modules/primeflex/core/_typography';
@import 'vendor/bulma/bulma';
@import 'common';
@import 'components/tabmenu';
@import 'components/custom-scrollbar';

.p-tooltip .p-tooltip-text {
  background-color: white;
  color: black;
  border-radius: 10px;
  border: 1px solid $colorBlue;
  text-align: center;
}